<template>
    <div>  
        <template v-if="!loader">
          <h5 class="text-center" style="font-family:TelefonicaLight, sans-serif; margin-top: -40px;"><b>Intentos {{info.intento + 1}} / 3</b></h5>
          <br> 
          <canvas v-if="ocultar == 0" id='myCanvas' ></canvas>    
           <b-row class="justify-content-center  mt-2" v-if="ocultar == 1" style=" z-index:1;">
             <b-col cols="11" md="3">  
                <center>
                  <img  src="https://prueba.convencioncomercial.com.mx/elementos/perder.png" class="rounded  float-center" alt="Image"  >
                    <p  :style="isMobile ? 'font-size:25px;' : 'font-size:35px; '" > 
                        <span  style="color:#8d3fe1;"><b>Has agotado tus oportunidades</b></span> 
                        <br> 
                        <span :style="isMobile ? 'font-size: 20px;' : 'font-size: 30px;'">Prepárate mejor para <br>la próxima vez</span>
                    </p>
                 </center>
             </b-col>
           </b-row>
          <audio id="audio" src="https://drive.google.com/uc?id=1vCDIoUu4NIjBlMOee-wTDsHBMc1bxzES" hidden></audio> 
        </template>
        <template v-else>
            <b-row class="justify-content-center mt-5" >
                <b-col cols="11"    style="color:#0b2739; font-size: 25px;"> 
                <center> <b-spinner style="color: #0b2739;" v-if="loader" ></b-spinner> Procesando solicitud... </center> 
                </b-col>
            </b-row> 
        </template>
    </div>
</template>
<script> 
import Vue from "vue";
import $ from "jquery";  
import * as moment from 'moment';
 import Swal from 'sweetalert2'
// import EventBus from '../../../main';
// import bus from "../../../utils/bus";
export default {
    data() {
        return{
            countdown: moment.utc(61).format('HH:mm:ss'),
            expires_in: null,
            interval: null,
            Clickinterval: false,
            loader:false,
            isMobile: this.$mobile(),
            correcto:0,
            empieza:0,
            boxw:"",
            boxh:"",
            can:{},
            ctx:"", 
            stepy:"",
            stepx:"",
            is_fail:false,
            rows:4,
            cols:4,
            tools:"",
            canvas:{},
            model:[],
            error:0,
            intento:0,
            userid:0,
            info:{},
            ocultar:0
        }  
    },
    destroyed () {
        clearInterval(this.interval);
    }, 
    mounted(){
      this.loader = true;
      this.userid = this.$route.params.folio;
      this.$bus.$emit("fondosolo", true)
      this.buscarUser();
    },
    created() {
        this.$bus.$on("intento_juego",
        () => { 
            this.buscarUser2();
        }); 

       this.$bus.$on("resetea_intento",
        () => { 
            if (this.ocultar == 0) {
                this.resetIntento(); 
            }
              
         }); 
    },
    methods:{ 
        getJuego(){
         this.loader = true;
         var url= 'juego/estatus2?email='+ this.user.email;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.loader = false;
                   if (this.isMobile)
                    {
                        this.$bus.$emit("cambi_fo", true)
                         window.scrollTo(0, 350);    
                    } 
                    this.correcto = data.correcto;
                    if (data.correcto != 0)
                    {
                        this.empieza = 0;
                        clearInterval(this.interval);    
                    }
                    if(data.correcto == 1){
                    this.$confetti.start({
                        particles: [
                            {
                            type: 'rect',
                            },
                            {
                            type: 'circle',
                            },
                        ],
                        defaultColors: [
                            '#5cb617',
                            '#ffffff',
                            '#187abe'
                        ],
                    });
                    setTimeout(() => {
                        this.$confetti.stop();
                    }, 2000);
                    }
                }
            );
        },
        buscarUser2(){
            var url= 'auth/info?id='+ this.userid;
                this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);
                        this.info = data.data;  
                       if (this.info.intento == 2) { 
                            this.correcto = 0;
                            this.empieza = 0;  
                            this.respondeJuego();
                            //   this.resetIntento();
                        }else{ 
                           this.guardaIntento();
                        }  
                    }
                );
        },
        guardaIntento(){
            var url= 'auth/intento?id='+ this.userid  + '&intento=1';
                this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);  
                        this.info = data.data;    
                    }
                );
        },
        resetIntento(){
          var url= 'auth/resetintento?id='+ this.userid;
                this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);  
                        this.info = data.data;    
                        if (this.info.intento < 3) { 
                             document.location.reload();
                        }
                        // console.log(this.info); 
                    }
                );
        },
        buscarUser(){
            var url= 'auth/info?id='+ this.userid;
                this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);
                        this.info = data.data;   
                         this.draw();
                        
                        // console.log(this.info); 
                    }
                );
        },
        empezarCronometro(){ 
            this.empieza = 1;
            this.countdown = moment.utc(32).format('HH:mm:ss');
            this.expires_in = 1;
            this._setInterval(); 
            setTimeout(() => {
              this.correcto = 1;
              this.empieza = 0; 
             clearInterval(this.interval);
             audio.pause();
            //  this.respondeJuego();
            }, 20000);
            let  audio = document.getElementById("audio");
            // audio.play();
            this.draw();
        },
        _setInterval: function() {
            // this.mostrarPregunta = 1;
            if(this.Clickinterval==false){
                this.interval = setInterval(() => {
                    if (this.expires_in === 0) {
                        this.empieza = 0;
                        clearInterval(this.interval);
                    } else {
                        this.expires_in += 1;
                        if(this.expires_in>0){
                            this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                        }
                    }
                }, 1000);
                this.Clickinterval = true;
            }
        },   
        respondeJuego(){     
            this.$api.post('juego/store', {
                    mrt: this.info.mrt,
                    idpdv: this.info.id_pdv,
                    email: this.info.email,
                    seccion: 'Juego',
                    puntos: this.correcto == 1 ? 10 : 0 ,
                    perfil: this.info.perfil,
                    respuesta: 'Piano',
                    tiempo: this.countdown,
                    correcto: this.correcto
                }).then(response => {
                        console.log(response);
                        this.loader = false;  
                        this.empieza = 0;   
                        this.ocultar = 1;
                        // this.resetIntento();
                    })
                    .catch(error => {
                        console.log(error);
                        this.loader = false;
                    });
        },
        draw(){   

          this.loader = false;

          $(function () {
              
              'use strict';
                    var  tools,canvas; 

                    tools = {
                        mt_rand(a, b) {
                            return a < b ?
                                    (Math.floor(Math.abs(a - b) * Math.random()) + a) : (Math.floor(Math.abs(a - b) * Math.random()) + b);
                        }
                    };    

                    function Mnws(canvas) {
                      if (this instanceof Mnws) {
                        this.can =  canvas;
                        this.ocultar = 0;
                        // this.box = {};
                        this.boxw = $( this.can).width();
                        this.boxh = $( this.can).height();
                        this.ctx = this.can.getContext('2d');
                        this.ctx.strokeStyle = '#eee';
                        this.ctx.fillStyle = '#333'; 
                        this.is_fail = false;
                        
                        this.rows = 4;
                        this.cols = 4;
                        this.speed = 1;
                        this.stepy = Math.floor(this.boxh / this.rows);
                        this.stepx = Math.floor(this.boxw / this.cols);

                      

                        this.initial();
                        this.events();  
                      } else {
                            return new Mnws(canvas);
                        }
                    }
        

                Mnws.prototype = {

                        initial : function () {
                                this.doCanvas();
                                this.drawYLines();
                                this.imgData = this.ctx.getImageData(0, 0, this.boxw, this.boxh);
                                this.createModel();
                                this.changeModel();
                                this.controlSpeed();
                        },

                       doCanvas : function () {
                            $(this.can).attr('width', this.boxw);
                            $(this.can).attr('height', this.boxh);
                        },

                        drawYLines : function () {
                            var lineNum = this.rows;
                            while (--lineNum) {
                                this.ctx.save();
                                this.ctx.beginPath();
                                this.ctx.strokeStyle = '#5bc500';
                                this.ctx.moveTo(this.stepx * lineNum + 0.5, 0);
                                this.ctx.lineTo(this.stepx * lineNum + 0.5, this.boxh);
                                this.ctx.stroke();
                                this.ctx.restore();
                            }
                        },

                        createModel : function () {
                            var i, num;
                            this.model = [];
                            num = this.rows + 2;
                            for (i = 1; i < num; i++) {
                                this.pushOneToModel();
                            }
                        },
                        pushOneToModel : function () {
                            var model = this.model,
                                rand = tools.mt_rand(0, this.cols),
                                initialY;
                            if (model.length === 0) {
                                initialY = this.boxh * (this.rows - 1) / this.rows + 0.5;
                                model.push({
                                    index : 0,
                                    y : initialY,
                                    rect_x : this.stepx * rand + 0.5,
                                    clicked : 0
                                });
                            }
                            model.push({
                                index : model[model.length - 1].index + 1,
                                y : model[model.length - 1].y - this.stepy,
                                rect_x : this.stepx * rand + 0.5,
                                clicked : 0
                            });
                        },
                        renderModel : function () {
                            var that = this;
                            this.ctx.putImageData(this.imgData, 0, 0, 0, 0, this.boxw, this.boxh);
                            this.model.forEach(function (obj) {
                                that.ctx.save();
                                that.ctx.strokeStyle = '#5bc500';
                                that.ctx.beginPath();
                                that.ctx.moveTo(0, obj.y);
                                that.ctx.lineTo(that.boxw, obj.y);
                                that.ctx.stroke();
                                that.ctx.restore();
                                if (!obj.clicked) {
                                    that.ctx.fillRect(obj.rect_x, obj.y - that.stepy, that.stepx, that.stepy);
                                    that.ctx.fillStyle = "#019df4";
                                } else {
                                    that.ctx.save(); 
                                    that.ctx.fillStyle = '#5bc500';
                                    that.ctx.fillRect(obj.rect_x, obj.y - that.stepy, that.stepx, that.stepy);
                                    that.ctx.restore();
                                }
                                if (obj.wrong) {
                                    that.ctx.save();
                                    that.ctx.globalAlpha =	obj.wrong.opacity;
                                    that.ctx.fillStyle = '#8d3fe1';
                                    that.ctx.fillRect(obj.wrong.rect_x, obj.y - that.stepy, that.stepx, that.stepy);
                                    that.ctx.restore();
                                }
                            });
                        },
                        changeModel : function () {
                            var model = this.model,
                                that = this;
                            this.timer = setInterval(function () {
                                if (model[0].y > that.boxh + that.stepy) {
                                    console.log(model[0]);
                                    if (model[0].clicked) {
                                        model.shift();
                                        that.pushOneToModel();
                                    } else {
                                        that.stop();
                                        Vue.prototype.$bus.$emit("intento_juego"); 
                                          setTimeout(() => { Vue.prototype.$bus.$emit("resetea_intento");  }, 3000);
                                            Swal.fire({
                                                title: "Fallaste",
                                                text: "Aún cuentas con más oportunidades",
                                                icon: "warning",
                                                showCancelButton: false, 
                                                confirmButtonColor: "#5cb615",
                                                cancelButtonColor: "#8d3fe1",
                                                confirmButtonText: "Inténtalo de nuevo"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        Vue.prototype.$bus.$emit("resetea_intento"); 
                                                    } 
                                                });  
                                    }
                                }
                                model.forEach(function (obj) {
                                    if (obj.clicked) {
                                        obj.opacity = obj.opacity || 1;
                                        obj.opacity = (obj.opacity - 0.1) > 0.2 ? (obj.opacity - 0.1) : 0.2;
                                    }
                                    if (obj.wrong) {
                                        // obj.wrong.opacity = obj.wrong.opacity === 1 ?  0.5 : 1;
                                        obj.wrong.opacity = obj.wrong.opacity || 1;
                                        obj.wrong.opacity = (obj.wrong.opacity - 0.1 > 0.5) ? (obj.wrong.opacity - 0.1) : 0.5;
                                    }
                                    obj.y += that.speed;
                                });
                                that.renderModel();
                            }, 1000 / 24);
                        },
                        controlSpeed : function () {
                            var that = this,
                                timer,
                                result;
                                console.log(result);
                                 that.speed = 2;
                            timer = setInterval(function () {
                                // that.speed = Math.floor(that.model[0].index / 5) >= 1 ? Math.floor(that.model[0].index / 5) : that.speed;
                                if (!that.is_fail) {
                                    result = Math.floor(that.model[0].index / 5);
                                   
                                     setTimeout(() => {
                                        that.speed = 6;
                                    }, 1000);
                                    console.log(that.speed);
                                } else {
                                    clearInterval(timer);
                                }
                            }, 1);
                        },
                        events : function () {
                            var that = this;
                            console.log(this.can);

                        //    var elem = document.getElementById('myCanvas');
                        //         elemLeft = elem.offsetLeft + elem.clientLeft,
                        //         elemTop = elem.offsetTop + elem.clientTop, 
                        
                        // this.can.addEventListener('click', function (e) {
                        //         var relX = e.pageX - e.offsetLeft;
                        //         var relY = e.pageY - e.offsetTop;
                                
                        //         that.whichClicked(relX , relY);
                             
                        //     }, false);


                            this.can.addEventListener('click', function (e) {

                                var parentOffset = $(this).offset();
                                var relX = e.pageX - parentOffset.left;
                                var relY = e.pageY - parentOffset.top;
                                
                                that.whichClicked(relX , relY);
                            });
                        },
                        whichClicked : function (x, y) {   
                            var  obj, i;
                            // var intento = 3; 
                            for (i = 0; i < this.model.length; i++) {
                                obj = this.model[i];
                                if (y < obj.y && y > obj.y - this.stepy) {
                                    if (x > obj.rect_x && x < obj.rect_x + this.stepx) {
                                        // console.log(obj.index);
                                        obj.clicked = 1;
                                        break;
                                    } else {
                                        obj.wrong = {};
                                        obj.wrong.opacity = 1;
                                        obj.wrong.rect_x = Math.floor(x / this.stepx) * this.stepx;
                                        this.stop();      
                                        Vue.prototype.$bus.$emit("intento_juego");  
                                        setTimeout(() => { Vue.prototype.$bus.$emit("resetea_intento");  }, 3000);
                                            Swal.fire({
                                                title: "Fallaste",
                                                text: "Aún cuentas con más oportunidades",
                                                icon: "warning",
                                                showCancelButton: false, 
                                                confirmButtonColor: "#5cb615",
                                                cancelButtonColor: "#8d3fe1",
                                                confirmButtonText: "Inténtalo de nuevo"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        Vue.prototype.$bus.$emit("resetea_intento"); 
                                                    }
 
                                                 });  
                                     } 
                                     
                                }
                            }
                        },
                        stop : function () {
                            var that = this; 
                            clearInterval(this.timer);
                            function auto() {
                                that.model.forEach(function (obj) {
                                    obj.y -= 4;
                                });
                                that.renderModel();
                                if (that.model[0].y < that.boxh) {
                                    clearInterval(that.timer2);
                                }
                            }
                            this.timer2 = setInterval(auto, 1000 / 24); 
                        },
                        fail() {
                         
                        },
                        getScore : function () {
                            var i;
                            for (i = 0; i < this.model.length; i++) {
                                if (!this.model[i].clicked) {
                                    return this.model[i].index;
                                }
                            }
                        }
                      };


                    canvas  = document.getElementById("myCanvas");
                    window.xx =  new Mnws(canvas); 
            });
        }, 
    }, 
}


</script>
<style> 
    @font-face {
        font-family: 'TelefonicaLight';
        src: url("http://localhost:8080/fonts/Telefonica-Light.otf") format("opentype");
    }

    @font-face {
        font-family: 'TelefonicaRegular';
        src: url("http://localhost:8080/fonts/Telefonica-Regular.otf") format("opentype");
    } 
 #confetti-canvas {
  z-index: 1000;
}
*{
  background: #fff;
  margin-top: -14px;
  font-family: TelefonicaRegular, serif;
}

#myCanvas{
    width:380px;height:590px;
    border:2px solid #aaa; 
  background: #fff;
  margin: 10px;
} 
.tran{
    transition:all 0.3s;
    -webkit-transition:all 0.3s;
    -moz-transition:all 0.3s;
    -o-transition:all 0.3s;
    -ms-transition:all 0.3s;
}

@media screen and (max-width: 900px) {
    #myCanvas{
        position:absolute;
        top:0;left:0;
        width:100%;height:100%;
        margin:0!important;
        border:none!important;
    }
}

@media screen and (max-width: 600px) {
    #myCanvas{
        position:absolute;
        top:0;left:0;
        width:100%;height:100%;
        margin:0!important;
        border:none!important;
    }
}

</style>